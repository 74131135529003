import * as React from "react";
import { Box, Card, CardActionArea, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import useUserData from "../../hooks/useUserData";
import HistoricalQueries from "../historicalQueries";
import "./index.scss";

const listOfDocs = require("~/public/static/img/list_of_docs.png");
const caseLaw = require("~/public/static/img/case_law.png");
const chat = require("~/public/static/img/chat.png");
const intro = require("~/public/static/img/intro.png");

const NAME_MAPPING: { [key: string]: string } = {
  COMMON_COURT: "Sądy powszechne",
  SUPREME_COURT: "Sąd Najwyższy",
  NATIONAL_APPEAL_CHAMBER: "Krajowa Izba Odwoławcza",
  CONSTITUTIONAL_TRIBUNAL: "Trybunał Konstytucyjny",
  WSA: "Wojewódzkie Sądy Administracyjne",
  NSA: "Naczelny Sąd Administracyjny",
};

export default function Home() {
  const [signature, setSignature] = React.useState("");
  const [newsClips, setNewsClips] = React.useState([]);
  const snackbar = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();
  const userData = useUserData();

  const navigate = useNavigate();

  const [numberOfSentences, setNumberOfSentences] = React.useState<{
    [key: string]: number;
  }>({});

  React.useEffect(() => {
    axios
      .get("/api/v1/sentences-number")
      .then((response) => {
        setNumberOfSentences(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    axios
      .get("/api/v1/news-clips")
      .then((response) => {
        setNewsClips(response.data.newsClips);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearch = async () => {
    await getCSRFToken();
    const saosId = await axios
      .get("api/v1/find-sentence?signature=" + signature)
      .then((response) => {
        return response.data.saos_id;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          snackbar.setMessage("Nie znaleziono orzeczenia o podanej sygnaturze");
          snackbar.setSeverity("error");
          snackbar.setOpen(true);
        }
      });

    if (saosId) {
      navigate(`/show-document?saosId=${saosId}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Home | Gaius Lex - asystent prawnika</title>
        <meta name="description" content="Gaius Lex - asystent prawnika" />
      </Helmet>
      <Box id="modules-box" className="mb-3 d-flex flex-column">
        <h4>Moduły</h4>
        <Grid container spacing={2} gap={2} justifyContent={"center"}>
          {(userData.userData.user_plan.name !== "subscription" ||
            userData.userData.user_plan.trial) && (
            <Card
              className="shortcut-menu hover-shadow"
              sx={{ maxWidth: 300, textAlign: "center" }}
              onClick={() => {
                const url = "https://calendly.com/wwydmanski/gaiuslex";
                window.open(url, "_blank");
              }}
            >
              <CardContent
                className="d-flex flex-row"
                sx={{ height: "fix-content" }}
              >
                <img
                  src={intro}
                  alt="Lista dokumentów"
                  className="img-fluid"
                  width={120}
                  height={"fit-content"}
                />
                <Stack
                  direction="column"
                  spacing={2}
                  className="my-auto mx-auto"
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="my-auto mx-auto"
                    sx={{ lineHeight: 1 }}
                  >
                    Umów się na wdrożenie
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          )}
          <Card
            className="shortcut-menu hover-shadow"
            sx={{ textAlign: "center", maxWidth: 300 }}
            onClick={() => {
              navigate("/interactive-search");
            }}
          >
            <CardContent
              className="d-flex flex-row"
              sx={{ height: "fix-content" }}
            >
              <img
                src={caseLaw}
                alt="Przeszukaj bazę"
                className="img-fluid"
                width={120}
                height={"fit-content"}
              />
              <Stack direction="column" spacing={2} className="my-auto mx-auto">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="my-auto mx-auto"
                  sx={{ lineHeight: 1 }}
                >
                  Wyszukiwanie Interaktywne
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  className="text-muted"
                  component="div"
                  sx={{ lineHeight: 1 }}
                >
                  Znajdź odpowiedzi na swoje pytania wśród pełnej bazy Gaius-Lex.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            className="shortcut-menu hover-shadow"
            sx={{ maxWidth: 300, textAlign: "center" }}
            onClick={() => {
              navigate("/chat");
            }}
          >
            <CardContent
              className="d-flex flex-row"
              sx={{ height: "fix-content" }}
            >
              <img
                src={chat}
                alt="Porozmawiaj z Gaiusem"
                className="img-fluid"
                width={120}
                height={"fit-content"}
              />
              <Stack direction="column" spacing={2} className="my-auto mx-auto">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="my-auto mx-auto"
                  sx={{ lineHeight: 1 }}
                >
                  Porozmawiaj z Gaiusem
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  className="text-muted"
                  component="div"
                >
                  Gaius w tym trybie ma pełny dostęp do bazy naszych dokumentów!
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            className="shortcut-menu hover-shadow"
            sx={{ maxWidth: 300, textAlign: "center" }}
            onClick={() => {
              navigate("/browse");
            }}
          >
            <CardContent
              className="d-flex flex-row"
              sx={{ height: "fix-content" }}
            >
              <img
                src={listOfDocs}
                alt="Lista dokumentów"
                className="img-fluid"
                width={120}
                height={"fit-content"}
              />
              <Stack direction="column" spacing={2} className="my-auto mx-auto">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="my-auto mx-auto"
                  sx={{ lineHeight: 1 }}
                >
                  Własna baza
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  className="text-muted"
                  component="div"
                >
                  Wyszukaj informacje we własnej bazie dokumentów.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Box>
      <Box id="info-box" className="mb-3 d-flex flex-column">
        <h4 className="mb-3">Liczba wyroków w bazie</h4>
        <Grid container justifyContent={"center"}>
          {Object.keys(numberOfSentences).map((key, index) => {
            const elAmount = Object.keys(numberOfSentences).length;
            const lastIndex = elAmount - 1;
            const pl = index !== 0 ? 1 : 0;
            const pr = index !== lastIndex ? 1 : 0;
            const xs = elAmount > 0 ? 12 / elAmount : 0;
            return (
              <Grid item xs={xs} pl={pl} pr={pr} pb={1} minWidth={150}>
                <Card className="px-2 h-100" sx={{ textAlign: "center" }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {numberOfSentences[key]}
                    </Typography>
                    <Typography gutterBottom variant="body2" component="div">
                      {NAME_MAPPING[key] || key}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box id="news-clips-box" className="mb-3 d-flex flex-column">
        <h4 className="mb-3">Ciekawostki sądowe z ostatnich dni</h4>
        <Stack direction={"column"} spacing={2}>
          {newsClips.map((newsClip: any) => {
            const href = `/show-document?docId=${newsClip.document_id}&source=${newsClip.source}`;
            let content = newsClip.content.replace(/<[^>]*>?/g, '').replace(/&nbsp;/g, ' ').replace(/```/g, '');
            content = content.replace(/Nagłówek:/g, '').replace(/Treść:/g, '');
            const title = content.split('\n')[0];
            const description = content.split('\n').slice(1).join('\n');
            return (
              <Grid item xs={12} md={6} lg={4} xl={3} key={content} className="news-clips-box">
                <Card className="px-2 h-100" sx={{ textAlign: "justify" }} onClick={() => {
                  navigate(href);
                }}>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">{title}</Typography>
                    <Typography gutterBottom variant="body1" component="div">
                      {description}
                    </Typography>
                    <Typography gutterBottom variant="body2" component="div" textAlign="end">
                      {newsClip.date}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Stack>
      </Box>
    </>
  );
}
